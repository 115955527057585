export default function useConsentCookies(values) {
  function deleteCookie(name) {
    const domains = [
      '.alfaconsignado.com.br',
      'hom.alfaconsignado.com.br',
      'lab.alfaconsignado.com.br',
      'localhost', // Para desenvolvimento local
      '127.0.0.1',
      '.doubleclick.net',
      '.google.com',
      'www.google.com',
    ]

    domains.forEach((domain) => {
      document.cookie = `${name}=; Max-Age=-99999999; path=/; domain=${domain}`
    })
  }
  // Obtenha o cookie e decodifique a string
  const alfaPermissions = values

  function extractRejectedCookies() {
    const rejectedCookies = []
    alfaPermissions.forEach(({ categoria, status, cookies }) => {
      if (status === 'REJEITADO') {
        cookies.forEach(({ itens }) => {
          rejectedCookies.push(...itens)
        })
      }
    })
    return rejectedCookies
  }

  function setCookies(cookies, value) {
    cookies.forEach((cookie) => {
      if (!value) {
        if (cookie.includes('*')) {
          const regex = new RegExp(cookie.replace('*', '.*'))
          const allCookies = document.cookie.split('; ')
          allCookies.forEach((cookieStr) => {
            const [key] = cookieStr.split('=')
            if (regex.test(key)) {
              deleteCookie(key)
            }
          })
        } else {
          deleteCookie(cookie)
        }
      }
    })
  }

  function applyPermissions() {
    const rejectedCookies = extractRejectedCookies()
    setCookies(rejectedCookies, false)
  }
  // Executa a aplicação de permissões
  applyPermissions()
  return {
    setCookies,
  }
}
