<!-- Template -->
<template>
  <div 
    v-if="props.showOnlyModalManage === true || shouldScroll === true"
    class="o-cookie-manager"
    :class="[
      { 'o-cookie-manager--in': props.showOnlyModalManage === true || shouldScroll === true },
      { 'o-cookie-manager--out': shouldScroll === false || shouldManage === true },
      { 'o-cookie-manager--manage-in': props.showOnlyModalManage === true || shouldManage === true },
      { 'o-cookie-manager--manage-out': shouldManage === false },
    ]"
  >
    <div class="o-cookie-manager__center" v-if="shouldManage">
      <AtomCookieManager
        @modal:close="toggleCloseManager"
        @cookies:save="saveCookies"
      />
    </div>

    <div class="o-cookie-manager__bottom" 
      :class="[
        { 'o-cookie-manager__bottom--out': shouldManage === true}
      ]" 
      v-if="!shouldManage || !props.showOnlyModalManage"
    >
      <div class="o-cookie-manager__bottom__description">
        Usamos cookies para melhorar sua navegação. Ao clicar em "Aceitar cookies", você terá acesso a todas 
        as funcionalidades do site. Saiba mais em nossa 

        <NuxtLink to="/politica-de-privacidade" target="_blank">Política de Privacidade</NuxtLink>.
        
        Mas, se preferir, escolha 
        
        <button type='button' @click="rejectCookies">rejeitar</button>.
      </div>
      
      <div class="o-cookie-manager__bottom__actions">
        <button 
          type="button"
          class="o-cookie-manager__bottom__actions__manage"
          @click="toggleManager"
        >Gerenciar cookies</button>

        <button
          type="button"
          class="o-cookie-manager__bottom__actions__accept-all"
          @click="() => saveCookies()"
        >Aceitar cookies</button>
      </div>
    </div>
    <AtomLoading :is-active="isLoading" />
    <AtomRequestModal
      :modal="requestModal"
      @modal:toggle="handleToggleRequestModal"
      class="o-cookie-manager__error"
    />
  </div>
</template>

<!-- Script -->
<script setup>
// Dependências
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useSettingsStore } from '~/stores/settings'
import services from '@/services'

// Composables
import useConsentCookies from '~/composables/useConsentCookies.js'
import userAgent from '~/composables/user-agent'
import useScrollLock from '~/composables/useLockScroll.js'

// Componentes
import AtomLoading from '@/components/atoms/AtomLoading/AtomLoading.vue'
import AtomCookieManager from '../../atoms/AtomCookieManager/AtomCookieManager.vue'
import AtomRequestModal from '~/components/transational/atoms/AtomRequestModal/AtomRequestModal.vue'

// Emits
const emit = defineEmits(['cookies:use', 'closeModal'])

//props
const props = defineProps({
  showOnlyModalManage: { typeof: Boolean, default: false }
})

// Pinia
const settingsStore = useSettingsStore()
const { storageSettings } = storeToRefs(settingsStore)
const { setStorageSettings } = settingsStore

// Variáveis
const shouldScroll = ref(storageSettings.value.hasActiveModal === null ? true : false)
const shouldManage = ref(false)
const isLoading = ref(false)
let intervalId = ref(null)
const requestModal = ref({
  isActive: false,
  title: '',
  content: '',
  align: 'center',
  actionsList: [
    { 
      isAlternative: false,
      label: 'Voltar',
      emit: 'modal:toggle'
    }
  ],
})

// Observadores
watch(() => props.showOnlyModalManage, (newVal) => {
  if (!newVal) {
    shouldManage.value = false;
  } else {
    shouldManage.value = true;
  }
});

watch(() => shouldManage.value, (newVal) => {
  useScrollLock(newVal)
});

// Ciclo de vida
onMounted(() => {
  //caso não existir permissoes definidas, irá deixar os cookies reijeitados
  if(storageSettings.value.permissions) {
    validCookies(storageSettings.value.permissions);
  } else {
    const permissionList = buildRequestObject(null, false);
    validCookies(permissionList);
  }
})

// Métodos
function toggleManager() {
  shouldManage.value = true;
}

function toggleCloseManager() {
  if(!props.showOnlyModalManage) {
    shouldManage.value = false;
  } else {
    //atualiza o emit no politica de provacidade
    emit('closeModal', false);
    shouldManage.value = false;
  }
}

function buildRequestObject(permissions = null, statusBool) {
  // Listagem de permissões
  const permissionList = []

  const status = statusBool ? 'ACEITO' : 'REJEITADO';

  // Categoria de items essenciais
  const essential = {
    categoria: "ESSENCIAL",
    status:  'ACEITO',
    cookies: [
      {
        criador:"Alfa",
        itens:[
          "settings"
        ]
      }
    ]
};

  // Categoria de items de desempenho
  const performance = {
    categoria: "DESEMPENHO",
    status:  status,
    cookies: [
      {
        criador:"Hotjar",
        itens:[
          "_hjSession_*",
          "_hjSessionUser_*",
          "_hjDonePolls" //Não tem na doc
        ]
      },
      {
        criador:"Google",
          itens:[
            "_ga",
            "_ga_*",
            "_gat_UA-*",
            "_gid", 
            "_gat_gtag_UA_*", //Não tem na doc
          ]
      },
      {
        criador:"Heap Analytics",
        itens:[
          "_hp2_id_*", //Não tem na doc
        ]
      },
      {
        criador:"Jira",
        itens:[
          "ajs_anonymous_id"
        ]
      },
      {
        criador:"Dynatrace",
        itens:[
          "dtCookie",
          "dtPC",
          "dtSa",
          "rxVisitor",
          "rxvt"
        ]
      }
    ]
};

  // Categoria de items de funcionalidade
  // const functionality = {
  //   categoria: 'FUNCIONAL',
  //   status: status,
  //   cookies: [
  //   ],
  // }

  // Categoria de items de publicidade
  const advertising = {
    categoria:"PUBLICIDADE_MARKETING",
    status: status,
    cookies:[
      {
        criador:"Google",
        itens:[
          "AEC",
          "IDE",
          "NID",
          "_gcl_au",
          "DV", //não tem na doc
          "_gcl_aw",
          "_gcl_gs", //Não tem na doc
          "test_cookie" //Não tem na doc
        ]
      }
    ]
  };

  if (permissions && Object.keys(permissions).length) {
    for(let [key, value] of Object.entries(permissions)) {
      // Categoria de items essenciais
      if (key === 'essential') {
        permissionList.push({ ...essential, status: value ? 'ACEITO' : 'REJEITADO' })
        continue
      }

      // Categoria de items de desempenho
      if (key === 'performance') {
        permissionList.push({ ...performance, status: value ? 'ACEITO' : 'REJEITADO' })
        continue
      }

      // if (key === 'functionality') {
      //   permissionList.push({ ...functionality, status: value ? 'ACEITO' : 'REJEITADO' })
      //   continue
      // }

      if (key === 'advertising') {
        permissionList.push({ ...advertising, status: value ? 'ACEITO' : 'REJEITADO' })
        continue
      }
    }

    return permissionList
  }

  permissionList.push(
    essential, 
    performance, 
    // functionality, 
    advertising
  )
  return permissionList
}

function validCookies(permissions) {
  //só irá chamar se existir algum item rejeitado
  const hasReject = permissions.some(i => i.status === "REJEITADO");

  if(hasReject && intervalId.value === null) {
    useConsentCookies(permissions);

    //ficará excluindo os cookies a cada 3s 
    intervalId.value = setInterval(() => {
      useConsentCookies(permissions);

      const hasRejectUpdate = permissions.some(i => i.status === "REJEITADO");
      if(!hasRejectUpdate) {
        clearInterval(intervalId.value);
        intervalId.value = null;
      }
    }, 3000);

    onBeforeUnmount(() => {
      clearInterval(intervalId.value);
      intervalId.value = null;
    });

  } else if (!hasReject && intervalId.value !== null) {
    //caso não tenha reijeitado, irá parar de excluir
    clearInterval(intervalId.value);
    intervalId.value = null;
  }
}

//post
async function saveCookies(permissions = null) {
  // Cria o objeto de envio de requisição
  const permissionList = await buildRequestObject(permissions, true);
  await save(permissionList);
}

async function rejectCookies() {
  // Cria o objeto de envio de requisição
  const permissionList = await buildRequestObject(null, false);
  await save(permissionList);
}

async function save(permissionList) {
  isLoading.value = true;

  let dto = {
    userAgent: userAgent(),
    detalhes: permissionList
  };

  try {
    const response = await services.cookies.cookiesAceite(dto)

    if (response.status === 200) {
      // Fecha modal de gerenciamento de cookies
      if (shouldManage.value) shouldManage.value = false

      // Envia o objeto de requisição
      emit('cookies:use', permissionList)

      // Insere o cookie de gerenciamento de cookies
      setStorageSettings({ hasActiveModal: false })

      // Insere a lista de permissões dentro dos cookies`
      setStorageSettings({ permissions: permissionList })

      // Injeta composable das categorias
      validCookies(permissionList);

      // Fecha o modal de informações sobre cookies
      setTimeout(() => {
        shouldScroll.value = false
      }, 1000)

      //atualiza o emit no politica de provacidade
      if(props.showOnlyModalManage) {
        emit('closeModal', shouldManage.value);
      }

      isLoading.value = false;
    }

    return { success: true }
  } catch (err) {
    const { response } = err;

    requestModal.value = {
      isActive: true,
      title: 'Desculpe, estamos com problemas.',
      content: '<p>Retorne em alguns instantes.</p>',
      actionsList: [{isAlternative: false, label: 'Voltar', emit: 'modal:toggle' }],
    };

    isLoading.value = false;

    return { success: false, err }
  }
}

function handleToggleRequestModal() {
  requestModal.value = {
    isActive: false,
    title: '',
    content: '',
    align: 'center',
    actionsList: [],
  }
}
</script>

<!-- Style -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.o-cookie-manager {
  $self: &;

  pointer-events: none;
  z-index: 999;
  position: fixed;
  grid-auto-rows: auto;
  bottom: 0;
  row-gap: 24px;
  width: 100%;
  height: 100%;
  padding: 12px;

  @media(hover: hover) {
    width: calc(100svw - 15px);
  }
  
  @media screen and (min-width: $tablet) {
    place-items: center;
  }

  &__center {
    z-index: 999;
    place-self: stretch;
    place-content: start stretch;
    padding: 30px 16px;
    border-radius: 10px;
    background-color: $alfa-support-1;
    @include box-shadow(#00468233, 0, 3px, 4px);
    overflow: hidden;
    
    @media screen and (min-width: $tablet) {
      place-self: center;
      width: clamp(334px, 100%, 904px);
      padding: 40px;
    }
  }

  &__bottom {
    display: grid;
    width: 100%;
    pointer-events: all;
    z-index: 997;
    place-self: end stretch;
    place-content: start stretch;
    row-gap: 24px;
    padding: 30px;
    border-radius: 10px;
    background-color: $alfa-support-1;
    @include box-shadow(#00468233, 0, 3px, 4px);
    
    @media screen and (min-width: $tablet) {
      place-self: end center;
      padding: 40px;
    }

    @media screen and (min-width: $min-desktop) {
      grid-auto-flow: column;
      column-gap: 48px;
    }

    &__description {
      display: inline;
      @include font-custom(10, 16, justify);

      a,
      button {
        cursor: pointer;
        text-decoration: underline;
        @include font-custom(10, 16, justify)
      }

      button {
        background-color: transparent;
        border: 0;
      }
    }

    &__actions {
      display: grid;
      place-content: start stretch;
      row-gap: 16px;
      
      @media screen and (min-width: $tablet) {
        grid-template-columns: repeat(2, 1fr);
        place-content: center stretch;
        column-gap: 32px;
      }

      &__manage {
        background-color: transparent;
        border: 0;
        place-self: center;
        @include font-custom(14, 16, center, "Montserrat SemiBold", $alfa-neutral-tertiary-1);
        cursor: pointer;

        @media screen and (min-width: $tablet) {
          place-self: end;
        }
        @media screen and (max-width: $tablet) {
          grid-row: 2;
        }
      }

      &__accept-all {
        background-color: transparent;
        border: 0;
        place-self: center;
        @include font-custom(14, 16, center, "Montserrat ExtraBold");
        cursor: pointer;
        
        @media screen and (min-width: $tablet) {
          place-self: start;
        }
        @media screen and (max-width: $tablet) {
          grid-row: 1;
        }
      }
    }

    &--out {
      display: none;
    }
  }

  &:not(&--in, &--out) {
    display: none;
  }

  &--in,
  &--out {
    display: grid;

    &:is(&#{ $self }--in) {
      #{ $self }__bottom {
        opacity: 0;
        @include animate-scroll(1, 3000);
      }
    }

    &:is(&#{ $self }--out) {
      #{ $self }__bottom {
        @include animate-scroll(2);
      }
    }
  }

  &:not(&--manage-in, &--manage-out) {
    #{ $self }__center {
      visibility: hidden;
    }
  }

  &:is(&--manage-in, &--manage-out) {
    #{ $self }__center {
      visibility: visible;
    }
  }

  &--manage-in,
  &--manage-out {
    &::before {
      z-index: 998;
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba($alfa-brand-primary-1, 0.25);
    }

    &:is(&#{ $self }--manage-in) {
      &::before {
        @include animate-modal-toggle(1);
      }

      #{ $self }__center {
        pointer-events: all;
        @include animate-scroll(1)
      }
    }

    &:is(&#{ $self }--manage-out) {
      &::before {
        @include animate-modal-toggle(2);
      }

      #{ $self }__center {
        @include animate-scroll(2)
      }
    }
  }

  &__error {
    pointer-events: all;
  }
}
</style>
