<!-- Template -->
<template>
  <div class="a-cookie-manager">
    <div class="a-cookie-manager__title">
      <span class="a-cookie-manager__title__text">
        Gerenciar cookies
      </span>

      <button type="button" class="a-cookie-manager__title__icon" @click="handleCloseModal">
        <IconCancelCircle fill="#00c5d7" :width="24" :height="24" />
      </button>
    </div>

    <div class="a-cookie-manager__tab">
      <button
        type="button"
        class="a-cookie-manager__tab__option"
        :class="{ 'a-cookie-manager__tab__option--active': activeTabId === 1 }"
        @click="setActiveTab(1)"
      >Cookies usados neste site</button>

      <button
        type="button"
        class="a-cookie-manager__tab__option"
        :class="{ 'a-cookie-manager__tab__option--active': activeTabId === 2 }"
        @click="setActiveTab(2)"
      >Conheça nossa declaração de cookies</button>
    </div>

    <div class="a-cookie-manager__wrapper">      
      <form 
        class="a-cookie-manager__wrapper__form"
        :class="{ 'a-cookie-manager__wrapper__form--hidden': activeTabId === 2 }"
      >
        <p class="a-cookie-manager__wrapper__form__description">Para melhorar a sua experiência na plataforma e prover serviços personalizados, utilizamos cookies.</p>
  
        <ul class="a-cookie-manager__wrapper__form__list">
          <li class="a-cookie-manager__wrapper__form__list__item">
            <div class="a-cookie-manager__wrapper__form__list__item__field">
              <input type="checkbox" name="essential" id="essential" checked disabled />
  
              <label for="essential">
                Cookies essenciais
              </label>
            </div>
  
            <p class="a-cookie-manager__wrapper__form__list__item__description">
              São cookies essenciais para o funcionamento do site. São armazenados temporariamente e expiram assim
              que o navegador é fechado.
            </p>
          </li>
  
          <li class="a-cookie-manager__wrapper__form__list__item">
            <div class="a-cookie-manager__wrapper__form__list__item__field">
              <input type="checkbox" name="performance" id="performance" v-model="performance" />
  
              <label for="performance">
                Cookies de desempenho
              </label>
            </div>
  
            <p class="a-cookie-manager__wrapper__form__list__item__description">
              Ajudam a melhorar o nosso site. Armazena e relata informações sobre como você navega, quantas vezes
              você acessou uma página e quanto gasta em um assunto, por exemplo.
            </p>
          </li>
  
          <li class="a-cookie-manager__wrapper__form__list__item">
            <div class="a-cookie-manager__wrapper__form__list__item__field">
              <input type="checkbox" name="functionality" id="functionality" v-model="functionality"/>
  
              <label for="functionality">
                Cookies funcionalidade
              </label>
            </div>
  
            <p class="a-cookie-manager__wrapper__form__list__item__description">
              Utilizamos estes cookies para melhorar a funcionalidade e permitir a personalização através do 
              armazenamento de suas preferências, tais como nome de usuário, idioma e localização.
            </p>
          </li>
  
          <li class="a-cookie-manager__wrapper__form__list__item">
            <div class="a-cookie-manager__wrapper__form__list__item__field">
              <input type="checkbox" name="advertising" id="advertising" v-model="advertising" />
  
              <label for="advertising">
                Cookies de publicidade
              </label>
            </div>
  
            <p class="a-cookie-manager__wrapper__form__list__item__description">
              Apresentam suas preferências. Assim, podemos mostrar anúncios de acordo com suas atividades de 
              navegação. Caso prefira desativar, anúncios menos relevantes podem aparecer para você.
            </p>
          </li>
        </ul>
  
        <div class="a-cookie-manager__wrapper__form__info">
          <p class="a-cookie-manager__wrapper__form__info__text">
            Lembre-se: você pode desabilitar os cookies acessando as configurações do seu navegador.
          </p>
  
          <p class="a-cookie-manager__wrapper__form__info__text">
            (
              <NuxtLink 
                to="https://support.google.com/chrome/answer/95647"
                external
                target="_blank"
              >Chrome</NuxtLink>
  
              |
  
              <NuxtLink 
                to="https://support.mozilla.org/pt-BR/kb/desative-cookies-terceiros-impedir-rastreamento"
                external
                target="_blank"
              >Firefox</NuxtLink>
  
              |
  
              <NuxtLink
                to="https://support.microsoft.com/pt-br/windows/gerenciar-cookies-no-microsoft-edge-exibir-permitir-bloquear-excluir-e-usar-168dab11-0753-043d-7c16-ede5947fc64d"
                external
                target="_blank"
              >Microsoft Edge</NuxtLink>

              |

              <NuxtLink
                to="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                external
                target="_blank"
              >Safari</NuxtLink>
            )
          </p>
  
          <p class="a-cookie-manager__wrapper__form__info__text">
            Ao clicar em "Salvar configurações" você aceita utilizar os cookies selecionados por você, além dos 
            essenciais.
          </p>
        </div>
      </form>
      
      <div 
        class="a-cookie-manager__wrapper__info"
        :class="{ 'a-cookie-manager__wrapper__info--hidden': activeTabId === 1 }"
      >
        <p class="a-cookie-manager__wrapper__info__description">
          Usamos cookies e outras tecnologias para que o site reconheça suas ações e cliques a partir de 
          informações sobre você e suas preferências. Com esses dados, é possível personalizar e melhorar sua 
          experiência, sugerir conteúdos, além de facilitar a navegação de forma segura. Para mais informações, 
          consulte nossa
          <NuxtLink to="/politica-de-privacidade" target="_blank">Política de Privacidade</NuxtLink>.
        </p>
      </div>
      
      <div
        class="a-cookie-manager__wrapper__actions"
        :class="{ 'a-cookie-manager__wrapper__actions--hidden': activeTabId === 2 }"
      >
        <button
          type="button"
          class="a-cookie-manager__wrapper__actions__accept"
          @click="handleAcceptCookies"
        >Aceitar todos os cookies</button>
  
        <button
          type="button"
          class="a-cookie-manager__wrapper__actions__save"
          @click="handleSaveCookies"
        >Salvar configurações</button>
      </div>
    </div>
    
  </div>
</template>

<!-- Script -->
<script setup>
// Componentes
import IconCancelCircle from '~/assets/icons/IconCancelCircle.vue'

// Eventos
const emit = defineEmits([
  'modal:close',
  'cookies:accept',
  'cookies:save'
])

// Variáveis
const activeTabId = ref(1)
const performance = ref(false)
const functionality = ref(false)
const advertising = ref(false)

// Métodos
function setActiveTab(id) {
  activeTabId.value = id
}

function buildPermissionsObject() {
  return {
    essential: true,
    performance: performance.value,
    functionality: functionality.value,
    advertising: advertising.value
  }
}

function handleCloseModal() {
  emit('modal:close')
}

function handleAcceptCookies() {
  performance.value = true
  functionality.value = true
  advertising.value = true
  const permissions = buildPermissionsObject()
  emit('cookies:save', permissions)
}

function handleSaveCookies() {
  const permissions = buildPermissionsObject()
  emit('cookies:save', permissions)
}
</script>

<!-- Style -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.a-cookie-manager {
  display: grid;
  place-content: start stretch;
  row-gap: 16px;
  height: 100%;

  // Título e botão de fechar modal
  &__title {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 24px;

    &__text {
      @include font-custom(22, 26, left, "Montserrat Bold");
    }

    &__icon {
      cursor: pointer;
      display: grid;
      place-content: center;
      background-color: transparent;
      border: 0;
      transition: transform 100ms linear;

      &:active {
        transform: scale(.85);
      }
    }
  }

  // Gerenciamento de tabs
  &__tab {
    position: relative;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;

    @media screen and (max-width: $tablet) {
      grid-auto-columns: auto;
    }

    &:after {
      z-index: 1;
      position: absolute;
      content: '';
      bottom: 1px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $alfa-neutral-tertiary-2;
    }

    &__option {
      cursor: pointer;
      padding: 0 24px;
      border: 0;
      background-color: transparent;
      white-space: normal;
      @include font-custom(14, 16, left, "Montserrat SemiBold");

      @media screen and (max-width: $tablet) {
        text-align: center;
        padding: 0 12px;
      }

      &:not(&--active) {
        padding-bottom: 8px;
      }

      &--active {
        z-index: 2;
        @include separator(100%, 8px, center, $alfa-brand-primary-1);
      }
    }
  }

  // Conteudo do modal
  &__wrapper {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    // place-content: start stretch;
    row-gap: 16px;

    @media screen and (max-width: $tablet) {
      padding-right: 0;
      overflow: hidden auto;
      @include scrollbar(5px);
      @include scrollbox-shadow();
    }

    // Tab de formulário
    &__form {
      display: grid;
      place-content: start stretch;
      row-gap: 24px;

      height: 350px;
      padding-right: 24px;
      overflow: hidden auto;
      @include scrollbar(5px);
      @include scrollbox-shadow();

      &--hidden {
        display: none;
      }

      @media screen and (max-width: $tablet) {
        height: auto !important;
      }

      &__description {
        @include font-custom(14, 16, justify);
      }

      &__list {
        display: grid;
        place-content: start stretch;
        row-gap: 24px;

        &__item {
          display: grid;
          place-content: start stretch;
          row-gap: 8px;

          &__field {
            display: grid;
            place-content: start;
            place-items: center;
            grid-auto-flow: column;
            column-gap: 16px;

            input[type="checkbox"] {
              @include checkbox();
              
              &[disabled] {
                opacity: 0.5;
                cursor: default;
              }
            }


            label {
              cursor: pointer;
              @include font-custom(14, 16, left);
            }
          }

          &__description {
            @include font-custom(14, 16, justify);
          }
        }
      }

      &__info {
        display: grid;
        place-content: start stretch;
        row-gap: 8px;

        &__text {
          @include font-custom(14, 16, left);

          a {
            text-decoration: underline;
          }
        }
      }
    }

    // Tab sobre declaração de cookies
    &__info {
      display: grid;
      place-content: start stretch;
      row-gap: 24px;
      height: calc(350px + 16px + 48px);
      padding-right: 24px;
      overflow: hidden auto;
      @include scrollbar(5px);
      @include scrollbox-shadow();

      &--hidden {
        display: none;
      }

      &__description {
        @include font-custom(14, 16, justify);

        a {
          text-decoration: underline;
        }
      }
    }

    // Botões de ação do modal
    &__actions {
      display: grid;
      place-content: start stretch;
      place-items: center;
      row-gap: 24px;
      margin: 16px 0 8px;

      grid-auto-flow: column;
      column-gap: 32px;
      margin: 0;

      @media screen and (max-width: $tablet) {
          max-height: 120px;
          grid-auto-flow: row;
        }

        &--hidden {
          display: none;
        }
      &__accept {
        @include button-custom(265, 16, 1, "Montserrat Medium", 10);
        
        @media screen and (min-width: $tablet) {
          place-self: end;
        }
      }

      &__save {
        @include button-custom(265, 16, 2, "Montserrat Medium", 10);
        
        @media screen and (min-width: $tablet) {
          place-self: start;
        }
      }
    }
  }
}
</style>
